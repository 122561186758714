import { useState } from "react";
import PropTypes from "prop-types";
import { useFormik, FormikProvider } from "formik";

import useTranslation from "sholdi-hooks/useTranslation";
import { twMerge } from "tailwind-merge";

import useRegisterToNewsletter from "sholdi-hooks/useRegisterToNewsletter";

import Mail from "sholdi-icons/Mail";
import Flex from "sholdi-primitives/atoms/Flex";
import Box from "sholdi-primitives/atoms/Box";
import Alert from "sholdi-primitives/atoms/Alert";
import Text from "sholdi-primitives/atoms/Text";
import Field from "../Field/FormikField";
import LoadingButton from "../LoadingButton";

import { getValidationSchema, initial } from "./formValues";

const NewsletterSignUp = ({ isFooter, className, ...props }) => {
  const { t } = useTranslation();
  const [buttonError, setButtonError] = useState(false);
  const { loading, error, register } = useRegisterToNewsletter();

  const formik = useFormik({
    initialValues: initial,
    validationSchema: getValidationSchema(t),
    onSubmit: async (vals) => register(vals),
  });

  const { errors, handleSubmit } = formik;

  const buttonContent = isFooter ? t("send") : t("users.logIn");
  return (
    <FormikProvider value={formik}>
      <Flex
        className={twMerge(
          isFooter
            ? "w-full flex-col lg:flex-row justify-between align-initial lg:align-row"
            : "",
          className,
        )}
        as="form"
        onSubmit={handleSubmit}
        {...props}
      >
        {isFooter && (
          <Box className="mb-9 lg:mb-0 w-full">
            <Text className="text-white mb-2 leading-7.5 font-medium text-xl">
              {t("newsletterNews")}
            </Text>
            <Text className="text-white">{t("newsletterNoSpam")}</Text>
          </Box>
        )}
        <Field
          errorColor="text-secondary-light"
          before={!isFooter && <Mail className="h-6 w-8.5 stroke-white" />}
          variant="newsletter"
          name="email"
          placeholder={t(
            isFooter ? "newsletterPlaceholder" : "users.yourEmail",
          )}
          className={twMerge(
            isFooter
              ? "h-full w-full lg:w-[480px] leading-6 text-white focus:outline-none focus:border-none mb-0 placeholder:text-white bg-transparent"
              : "border-white focus-within:border-white",
          )}
        />
        <LoadingButton
          type="submit"
          loading={loading}
          variant="none"
          buttonSize="xl"
          onClick={() => setButtonError(true)}
          className={twMerge(
            "font-medium text-white mt-4 w-full lg:w-auto lg:mt-0 flex-none h-12",
            isFooter ? "bg-primary-dark" : "bg-primary-light",
            errors?.newsletterEmail && buttonError && "mb-6",
          )}
        >
          {buttonContent}
        </LoadingButton>
      </Flex>
      {error && (
        <Alert variant="error" className="mt-4" width="full">
          {error}
        </Alert>
      )}
    </FormikProvider>
  );
};

NewsletterSignUp.displayName = "NewsletterSignUp";

NewsletterSignUp.propTypes = {
  isFooter: PropTypes.bool,
  className: PropTypes.string,
};

export default NewsletterSignUp;
