import toast from "react-hot-toast";

import Check from "sholdi-icons/Check";
import Triangle from "sholdi-icons/Triangle";
import AlertCircle from "sholdi-icons/AlertCircle";

import IconToast from "./Toast/IconToast";

const SETTINGS = {
  success: { icon: Check, color: "success" },
  error: { icon: Triangle, color: "error" },
  warning: { icon: AlertCircle, color: "warning" },
};

export const getToast = (
  type = "error",
  message,
  duration = "200",
  position = "bottom-center",
) =>
  toast(
    (to) => (
      <IconToast
        iconComponent={SETTINGS[type].icon}
        title={message}
        inverse={false}
        isIcon
        color={SETTINGS[type].color}
        onClose={() => toast.dismiss(to.id)}
      />
    ),
    {
      position,
      duration,
    },
  );
