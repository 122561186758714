import { forwardRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import Spinner from "sholdi-primitives/atoms/Spinner";
import Button from "sholdi-primitives/atoms/Button";

const LoadingButton = forwardRef(
  (
    {
      loading = false,
      spinnerProps,
      icon,
      children,
      className,
      iconProps,
      ...props
    },
    ref,
  ) => (
    <Button
      icon={loading ? null : icon}
      iconProps={iconProps}
      ref={ref}
      className={twMerge(
        loading ? "pointer-events-none p-2" : "pointer-events-auto",
        "gap-2",
        className,
      )}
      {...props}
    >
      {!loading && children}
      {loading && <Spinner {...spinnerProps} />}
    </Button>
  ),
);

LoadingButton.displayName = "LoadingButton";

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  spinnerProps: PropTypes.shape({}),
  spinnerColor: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.func,
  ]),
  iconProps: PropTypes.shape({}),
  className: PropTypes.string,
};

export default LoadingButton;
